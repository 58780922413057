import type MarkdownIt from 'markdown-it';

export default function (md: MarkdownIt) {
	md.inline.ruler.before('text', 'citation', function (state, silent) {
		const str = state.src.slice(state.pos);

		/**
		 * Matches the following:
		 * [^00^]
		 */
		const citationRegexp = /^\[\^[^\^]+\^]/;
		const match = citationRegexp.exec(str);

		if (!match) {
			return false;
		}

		if (silent) {
			return true;
		}

		// Just skip citation from parsing and don't render them in the output.
		state.pos += match[0].length;
		return true;
	});
}
