import type Token from 'markdown-it/lib/token';

import type { Schema } from '@atlaskit/editor-prosemirror/model';

interface TokenType {
	block?: string;
	node?: string;
	mark?: string;
	attrs?: (tok: Token) => any;
}

export interface SchemaMapping {
	nodes: { [key: string]: string | string[] };
	marks: { [key: string]: string | string[] };
}

export function filterMdToPmSchemaMapping(schema: Schema, map: Record<string, TokenType>) {
	return Object.keys(map).reduce((newMap: Record<string, TokenType>, key: string) => {
		const value = map[key];
		const block = value.block || value.node;
		const mark = value.mark;
		if ((block && schema.nodes[block]) || (mark && schema.marks[mark])) {
			newMap[key] = value;
		}
		return newMap;
	}, {});
}
export const PM_SCHEMA_TO_MD_MAPPING: SchemaMapping = {
	nodes: {
		blockquote: 'blockquote',
		paragraph: 'paragraph',
		rule: 'hr',
		// lheading (---, ===)
		heading: ['heading', 'lheading'],
		codeBlock: ['code', 'fence'],
		listItem: 'list',
		image: 'image',
	},
	marks: {
		em: 'emphasis',
		strong: 'text',
		link: ['link', 'autolink', 'reference', 'linkify'],
		strike: 'strikethrough',
		code: 'backticks',
	},
};

// FIXME: Remove extendMarkdownSupport
export const getMdToPmMapping = (extendMarkdownSupport: boolean): Record<string, TokenType> => ({
	blockquote: { block: 'blockquote' },
	paragraph: { block: 'paragraph' },
	em: { mark: 'em' },
	strong: { mark: 'strong' },
	link: {
		mark: 'link',
		attrs: (tok: Token) => {
			// @ts-expect-error
			if (tok.pmAttrs) {
				// @ts-expect-error
				return tok.pmAttrs;
			} else {
				return {
					href: tok.attrGet('href'),
					title: tok.attrGet('title') || null,
				};
			}
		},
	},
	hr: { node: 'rule' },
	heading: {
		block: 'heading',
		attrs: (tok: Token) => ({ level: +tok.tag.slice(1) }),
	},
	softbreak: { node: 'hardBreak' },
	hardbreak: { node: 'hardBreak' },
	code_block: { block: 'codeBlock' },
	list_item: { block: 'listItem' },
	bullet_list: { block: 'bulletList' },
	ordered_list: {
		block: 'orderedList',
		attrs: (tok: Token) => ({
			order: typeof tok.attrGet('start') === 'number' ? tok.attrGet('start') : 1,
		}),
	},
	code_inline: { mark: 'code' },
	code: { mark: 'code' },
	fence: {
		block: 'codeBlock',
		// we trim any whitespaces around language definition
		attrs: (tok: Token) => ({
			language: (tok.info && tok.info.trim()) || null,
		}),
	},
	mediaInline: {
		node: 'mediaInline',
		attrs: (token: Token) => {
			if (extendMarkdownSupport) {
				// @ts-expect-error
				return token.pmAttrs;
			}
			return {};
		},
	},
	media_single: {
		block: 'mediaSingle',
		attrs: (token: Token) => {
			if (extendMarkdownSupport) {
				// @ts-expect-error
				return token.pmAttrs;
			}
			return {};
		},
	},
	media: {
		node: 'media',
		attrs: (token: Token) => {
			// When token.pmAttrs is missing it means it's an external image.
			// @ts-expect-error
			if (token.pmAttrs) {
				// @ts-expect-error
				return token.pmAttrs;
			}
			return {
				url: token.attrGet('url'),
				type: 'external',
			};
		},
	},
	caption: {
		block: 'caption',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	alignment: {
		mark: 'alignment',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	breakout: {
		mark: 'breakout',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	border: {
		mark: 'border',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	textColor: {
		mark: 'textColor',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	underline: {
		mark: 'underline',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	subsup: {
		mark: 'subsup',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	emoji: {
		node: 'emoji',
		attrs: (token: Token) => {
			if (extendMarkdownSupport) {
				// @ts-expect-error
				return token.pmAttrs;
			}
			return {
				shortName: `:${token.markup}:`,
				text: token.content,
			};
		},
	},
	inlineCard: {
		node: 'inlineCard',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	date: {
		node: 'date',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	status: {
		node: 'status',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	mention: {
		node: 'mention',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	placeholder: {
		node: 'placeholder',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	table: {
		block: 'table',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	tr: {
		block: 'tableRow',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	th: {
		block: 'tableHeader',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	td: {
		block: 'tableCell',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	s: { mark: 'strike' },
	strike: { mark: 'strike' },
	task_list: { block: 'taskList' },
	task_item: {
		block: 'taskItem',
		attrs: (tok: Token) => ({
			state: tok.meta,
		}),
	},
	layoutSection: {
		block: 'layoutSection',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	layoutColumn: {
		block: 'layoutColumn',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	panel: {
		block: 'panel',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	decisionList: {
		block: 'decisionList',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	decisionItem: {
		block: 'decisionItem',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	expand: {
		block: 'expand',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	extension: {
		node: 'extension',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	bodiedExtension: {
		block: 'bodiedExtension',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	nestedExpand: {
		block: 'nestedExpand',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
	inlineExtension: {
		node: 'inlineExtension',
		attrs: (token: Token) => {
			// @ts-expect-error
			return token.pmAttrs;
		},
	},
});
