import MarkdownIt from 'markdown-it';
import { markdownItTable } from 'markdown-it-table';

import { MarkdownParser } from '@atlaskit/editor-prosemirror/markdown';
import type { Node, Schema } from '@atlaskit/editor-prosemirror/model';
import { Fragment } from '@atlaskit/editor-prosemirror/model';

import type { IDMap } from '../convert-prosemirror-to-markdown/pm-markdown/serializer';
import type { FeatureToggles } from '../feature-keys';

import type { SchemaMapping } from './content-mapping';
import {
	filterMdToPmSchemaMapping,
	getMdToPmMapping,
	PM_SCHEMA_TO_MD_MAPPING,
} from './content-mapping';
import markdownItCitation from './markdown-to-pm/md/citation-md-plugin';
import markdownItCustomBlock from './markdown-to-pm/md/custom-block-md-plugin';
import markdownItCustomInline from './markdown-to-pm/md/custom-inline-md-plugin';
import markdownItDecisionList from './markdown-to-pm/md/decisionlist-md-plugin';
import markdownItHardbreak from './markdown-to-pm/md/hardbreak-md-plugin';
import markdownItLinkify from './markdown-to-pm/md/linkify-md-plugin';
import { markdownItMedia } from './markdown-to-pm/md/media-md-plugin';
import markdownItNewline from './markdown-to-pm/md/newline-md-plugin';
import markdownItParagraph from './markdown-to-pm/md/paragraph-md-plugin';
import markdownItTaskList from './markdown-to-pm/md/tasklist-md-plugin';
import { escapeLinks } from './markdown-to-pm/utils/hyperlink';

function getFragmentBackingArray(fragment: Fragment): ReadonlyArray<Node> {
	return (fragment as any).content as Node[];
}

function mapFragment(
	content: Fragment,
	callback: (node: Node, parent: Node | null, index: number) => Node | Node[] | Fragment | null,
	parent: Node | null = null,
) {
	const children = [] as Node[];
	for (let i = 0, size = content.childCount; i < size; i++) {
		const node = content.child(i);
		const transformed = node.isLeaf
			? callback(node, parent, i)
			: callback(node.copy(mapFragment(node.content, callback, node)), parent, i);
		if (transformed) {
			if (transformed instanceof Fragment) {
				children.push(...getFragmentBackingArray(transformed));
			} else if (Array.isArray(transformed)) {
				children.push(...transformed);
			} else {
				children.push(transformed);
			}
		}
	}
	return Fragment.fromArray(children);
}

// WARNING
// If this import changes -- it means we have a breaking change for products
// where currently it's expected they are using older versions of editor-common
/**
 * Converts a markdown string into a Prosemirror document fragment
 *
 * @param markdown - The markdown string to convert
 * @param schema - The Prosemirror schema to use for the conversion
 * @param idMap - The id map to use for the conversion
 * @param featureToggles - Optional feature toggles to use for the conversion.
 * @returns The converted Prosemirror document fragment, the number of html nodes converted to Prosemirror nodes and the number of html nodes converted to fallback Prosemirror nodes
 */
export function convertMarkdownToProsemirror({
	markdown,
	schema,
	idMap,
	featureToggles = {},
}: {
	markdown: string;
	schema: Schema;
	idMap: IDMap;
	/**
	 * See {@link FeatureToggles} for available features
	 */
	featureToggles?: FeatureToggles;
}): {
	htmlConvertedToNodes: number;
	htmlConvertedToFallbackNodes: number;
	pmFragment: Fragment;
} {
	const { markdownPlus } = featureToggles;

	const tokenizer = new MarkdownIt('zero', {
		html: false,
	});

	tokenizer.enable([
		// Process html entity - &#123;, &#xAF;, &quot;, ...
		'entity',
		// Process escaped chars and hardbreaks
		'escape',
		'newline',
	]);
	(['nodes', 'marks'] as (keyof SchemaMapping)[]).forEach((key) => {
		for (const idx in PM_SCHEMA_TO_MD_MAPPING[key]) {
			if (schema[key][idx]) {
				tokenizer.enable(PM_SCHEMA_TO_MD_MAPPING[key][idx]);
			}
		}
	});

	const htmlConversionCounts = {
		htmlConvertedToNodes: 0,
		htmlConvertedToFallbackNodes: 0,
	};

	if (markdownPlus) {
		tokenizer.use(markdownItCustomBlock, idMap, htmlConversionCounts);
		tokenizer.use(markdownItDecisionList, idMap);
		tokenizer.use(markdownItCustomInline, idMap, featureToggles, htmlConversionCounts);
	}

	tokenizer.use(markdownItCitation, idMap);

	tokenizer.use(markdownItParagraph);
	// enable modified version of linkify plugin
	// @see https://product-fabric.atlassian.net/browse/ED-3097

	tokenizer.use(markdownItLinkify);
	tokenizer.use(markdownItNewline);
	tokenizer.use(markdownItHardbreak);

	// Custom Table tokenizer
	if (schema.nodes.table) {
		tokenizer.use(markdownItTable);
	}

	// Custom Media tokenizer
	if (schema.nodes.media && schema.nodes.mediaSingle) {
		tokenizer.use(markdownItMedia);
	}

	// Custom TaskList tokenizer
	if (schema.nodes.taskList) {
		tokenizer.use(markdownItTaskList);
	}

	const markdownParser = new MarkdownParser(
		schema,
		tokenizer,
		filterMdToPmSchemaMapping(schema, getMdToPmMapping(!!markdownPlus)),
	);

	if (markdown === '') {
		return {
			htmlConvertedToNodes: 0,
			htmlConvertedToFallbackNodes: 0,
			pmFragment: Fragment.empty,
		};
	}

	// normalize duplicated backticks (needed as a safeguard to avoid rendering malformed escape characters coming from backed)
	// the case with newline characters
	markdown = markdown.replace(/\\n/g, '\n');
	// the case with custom field attributes (limit only to data- attributes inside custom fields)
	if (markdownPlus) {
		markdown = markdown.replace(/<custom[^>]*?>/g, function (match) {
			return match.replace(/(data-\w+=)\\\"(.*?)\\\"/g, '$1"$2"');
		});
	}

	let prosemirrorNode = markdownPlus
		? markdownParser.parse(markdown)
		: markdownParser.parse(escapeLinks(markdown));

	if (!prosemirrorNode.content) {
		throw new Error('Markdown conversion did not result in a valid document being created');
	}

	// This logic is duplicated from editor-cores paste transformation.
	// The editor-plugin-ai package is avoiding sharing dependencies with editor-core to support
	// products using it with various versions of editor packages.
	// The duplication is from the following file:
	// packages/editor/editor-core/src/plugins/media/utils/media-single.ts

	// WARNING
	// If the `mapFragment` usage changes -- it means we have a breaking change for products
	// where currently it's expected they are using older versions of editor-common
	const mappedContent = mapFragment(prosemirrorNode.content, (node) => {
		if (node.type.name === 'media') {
			return schema.nodes.media.createChecked(
				{
					...node.attrs,
					// Adding this attribute results in the media node being rendered
					// initially using the external url, but then triggering an upload
					// of the external url as an atlassian artifact, and this media
					// will then be replaced to use the atlassian artifact.
					//
					// This is done in the media nodeview by checking the attributes
					// when rendering.
					__external: node.attrs.type === 'external',
					__mediaTraceId: null,
				},
				node.content,
				node.marks,
			);
		}
		return node;
	});

	return {
		...htmlConversionCounts,
		pmFragment: mappedContent,
	};
}

/**
 * Uses the `convertMarkdownToProsemirror` function to convert markdown to a ProseMirror node, instead of a fragment.
 *
 * The node that is returned is a ProseMirror document with the markdown converted to ProseMirror nodes nested in the document.
 *
 * This will throw an error if the fragment returned from `convertMarkdownToProsemirror` cannot be converted into a document node.
 *
 * @param markdown - The markdown string to convert
 * @param schema - The Prosemirror schema to use for the conversion
 * @param idMap - The id map to use for the conversion
 * @param featureToggles - Optional feature toggles to use for the conversion.
 * @returns The converted Prosemirror document node
 */
export function backendConvertMarkdownToProsemirrorNode({
	markdown,
	schema,
	idMap,
	featureToggles = {},
}: {
	markdown: string;
	schema: Schema;
	idMap: IDMap;
	/**
	 * See {@link FeatureToggles} for available features
	 */
	featureToggles?: FeatureToggles;
}): Node {
	const fragment = convertMarkdownToProsemirror({
		markdown,
		schema,
		idMap,
		featureToggles: {
			markdownPlus: true,
			...featureToggles,
		},
	});

	try {
		const node = schema.nodes.doc.createChecked({}, fragment.pmFragment);
		return node;
	} catch {
		throw new Error('Markdown conversion did not result in a valid ProseMirror node');
	}
}
